<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-card-title>Engines register requests</v-card-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="requests"
          :options="tableOptions"
          :search="search"
          class="elevation-0 border"
          :loading="loading"
          :no-data-text="loading ? 'Loading…' : 'No data available'"
        >
          <template v-slot:top>
            <v-row class="px-3">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  :style="$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>

          <template v-if="requests.length > 0" v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item._key">
                <td>{{ item.name }}</td>
                <td>{{ item.serial_number }}</td>
                <td>{{ item.user.name }}</td>
                <td class="justify-center layout px-0">
                  <v-btn
                    small
                    @click="acceptRequest(item)"
                    class="mr-2"
                    color="success"
                  >
                    Accept
                  </v-btn>

                  <v-btn small @click="declineRequest(item)" color="error">
                    Decline
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash"

export default {
  data() {
    return {
      actions: {
        accept: "accepted",
        decline: "declined",
      },
      search: "",
      dialog: false,
      tableOptions: {
        sortBy: ["name"],
      },
      selected: [],
      headers: [
        {
          text: "Name",
          align: "left",
          value: "name",
        },
        {
          text: "Serial number",
          value: "serial_number",
        },
        {
          text: "User",
          value: "user",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      requests: [],
      editedIndex: -1,
      editedItem: {
        name: "",
        serial_number: "",
        user: "",
      },
      defaultItem: {
        first_name: "",
        serial_number: "",
        user: "",
      },
      loading: false,
    }
  },
  methods: {
    toggleAll() {
      if (this.selected.length) this.selected = []
      else this.selected = this.requests.slice()
    },

    changeSort(header) {
      let column = header.value
      if (header.sortable === undefined || header.sortable === true) {
        if (this.pagination.sortBy === column) {
          this.pagination.descending = !this.pagination.descending
        } else {
          this.pagination.sortBy = column
          this.pagination.descending = false
        }
      }
    },

    getRequestsList: function () {
      let app = this
      app.$store.dispatch("requestsEnginesGetList").then(function (response) {
        app.requests = response.data.data
      })
    },

    acceptRequest(item) {
      let app = this
      let data = {
        pk: item.pk,
        action: app.actions.accept,
      }

      app.$store
        .dispatch("requestsEnginesAccept", data)
        .then(({ data }) => {
          app.getRequestsList()
        })
        .catch(({ response }) => {})
    },

    declineRequest(item) {
      let app = this
      let data = {
        pk: item.pk,
        action: app.actions.decline,
      }

      app.$store
        .dispatch("requestsEnginesDecline", data)
        .then(({ data }) => {
          app.getRequestsList()
        })
        .catch(({ response }) => {})
    },

    replaceEngineRequest() {
      alert(
        "Replace functionality not implemented, change for package unlimited limit of engines"
      )
    },

    handleValidationErrors(response) {
      let self = this
      _.forEach(response.data, function (val, key) {
        self.$validator.errors.add({
          scope: "form-user",
          field: key,
          msg: val,
        })
      })
    },
  },
  created: function () {
    this.getRequestsList()
  },
}
</script>
